@import 'custom';
@import '~leaflet/dist/leaflet.css';

/**
 * Layout
*/
.layout-topleft {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10000;
}

@media screen and (max-width: 1024px) {
  .layout-topleft {
    position: absolute;
    top: 5px;
    left: 0px;
    z-index: 10000;
    width: 100%;
  }

  .card-address-inputgroup {
    width: 100%;
  }
}

.tooltip {
  z-index: 100000 !important;
}

/**
 * Map
*/
@media screen and (max-width: 1024px) {
  .leaflet-top {
    top: 100px !important;
  }
}

/**
 * SearchAddress
 */
 .rbt {
  min-width: 500px;
}

.rbt .rbt-menu {
  z-index: 10001;
}

@media screen and (max-width: 1024px) {
  .rbt {
    min-width: 0px;
    flex: 1;
  }
}

/**
 * Sidebar
 */
.sidebar {
  width: 80px;
  overflow: hidden;
  transition: width 1s ease;
}

.sidebar.expanded {
  width: 280px;
}

.menu-label {
  opacity: 0;
  position: absolute; /* Empêche les labels d'influencer la disposition */
  left: 60px; /* Position initiale à droite de l'icône */
  white-space: nowrap; /* Empêche le retour à la ligne */
  transition: opacity 0.5s ease, left 2s ease; /* Animation fluide pour apparaitre */
}

.menu-title {
  opacity: 0;
  position: absolute; /* Empêche les labels d'influencer la disposition */
  left: 0px; /* Position initiale à droite de l'icône */
  white-space: nowrap; /* Empêche le retour à la ligne */
  transition: opacity 0.5s ease, left 2s ease; /* Animation fluide pour apparaitre */
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 25px;
}

.sidebar.expanded .menu-title {
  opacity: 1;
  left: 0px; /* Ajuster pour un espacement précis lorsque le menu est élargi */
}

.sidebar.expanded .menu-label {
  opacity: 1;
  left: 70px; /* Ajuster pour un espacement précis lorsque le menu est élargi */
}

.sidebar .nav-link.active {
  border-radius: 15px;
  background-color: #dbdbdb;
}

.sidebar .nav-link.disabled {
  cursor: none;
  color: #6c757d;
}